<template>
  <component :is="contentComponent" />
</template>

<script>

const _m = () => import('@/pages/answer/m.vue') //代码切割
const _pc = () => import('@/pages/answer/pc.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Your Answer from AnswerBookhub.homes',
      meta: [
        {
          name: 'description',
          content: 'Here is the information you requested from AnswerBookhub.homes. Our AI-powered platform, driven by Ghat GPT, provides you with well-researched and accurate answers to your questions. Use this information to expand your knowledge and make well-informed decisions.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'deviceType',
    ])
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = _pc
      return
    }
    this.contentComponent = _m
  },
};
</script>
